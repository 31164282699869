<template>
  <div class="partner">
    <p class="title">合作伙伴</p>
    <p class="sub-title">COOPERATIVE PARTNER</p>
    <div class="partner-container">
      <card-title title="深度合作"></card-title>
      <vue-seamless-scroll
        :data="[[], [], [], [], []]"
        class="wrap"
        :class-option="scrollOption"
      >
        <div class="list">
          <div class="item cmg">
            <img src="@/assets/images/home/中新集团.png" />
            <span>中国新闻出版传媒集团有限公司</span>
          </div>
          <div class="item">
            <img src="@/assets/images/home/临港新片区.jpg" />
            <span>中国（上海）自由贸易试验区临港新片区</span>
          </div>
          <div class="item">
            <img src="@/assets/images/home/临港科技城.jpeg" />
            <span>临港科技城</span>
          </div>
          <div class="item">
            <img src="@/assets/images/home/跨境数科.jpeg" />
            <span>临港跨境数科</span>
          </div>
          <div class="item">
            <img src="@/assets/images/home/dinghe.png" />
            <span>DING HE</span>
          </div>
        </div>
      </vue-seamless-scroll>

      <card-title title="合作平台"></card-title>
      <div class="list">
        <div class="item">
          <img src="@/assets/images/home/TikTok.jpg" />
          <span>TikTok</span>
        </div>
        <div class="item">
          <img src="@/assets/images/home/Amazon.jpg" />
          <span>Amazon</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: {
    CardTitle,
    vueSeamlessScroll,
  },
  data() {
    return {
      scrollOption: {
        direction: 2,
      },
    };
  },

  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="less" scoped>
.partner {
  width: 100%;
  height: 845px;
  background: url("../../assets/images/home/partner.jpg") center no-repeat;
  background-size: cover;
  padding-top: 67px;
  .title {
    color: #39425c;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  .sub-title {
    font-size: 18px;
    color: #39425c;
    margin-bottom: 62px;
    letter-spacing: 11px;
    text-align: center;
    opacity: 0.5;
    font-weight: bold;
  }

  .partner-container {
    width: 1440px;
    margin: 0 auto;

    .wrap {
      width: 1440px;
      overflow: hidden;
    }

    .list {
      display: flex;
      margin-top: 15px;
      margin-bottom: 90px;
      .item {
        flex-shrink: 0;
        width: 350px;
        height: 162px;
        border: 1px solid #dddddd;
        border-radius: 10px;
        background-color: #ffffff;
        position: relative;
        margin-right: 13px;
        &.cmg {
          padding: 50px 90px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        span {
          display: inline-block;
          width: 350px;
          position: absolute;
          font-size: 18px;
          color: #39425c;
          bottom: -37px;
          left: 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
