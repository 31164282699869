export const TokenKey = "token";

export const AuthKey = "auth";

export const AuthType = {
  [TokenKey]: TokenKey,
  [AuthKey]: AuthKey,
};

export const AcceptImgType = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/jpg",
];

// 上传图片限制，10M
export const UploadImgLimit = 1024 * 1024 * 10;

export const ConnectionTypeEnum = Object.freeze({
  Vpn: 1,
  Direct: 2,
});
