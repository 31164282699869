import axios from "axios";
// import { message } from 'ant-design-vue'
// import { Loading } from 'element-ui'
// import { noop } from 'lodash'
import MyToast from "@/components/MyToast";
import { TokenKey } from "@/config/system";
import { AutoLoginExpires } from "@/config/user";
import JsCookie from "js-cookie";
import store from "@/store";
import router from "../router";
import { removeUserData } from "../utils/utils";

// let loading = noop

const service = axios.create({
  timeout: 60 * 1000, // 请求超时
  baseURL: "/backend",
});
// 请求拦截列表
const interceptUrlRequest = [
  "customer/login",
  "customer/register",
  "admin/user/sms-code",
  "customer/forget",
  "web/product/list/1",
  "web/product/list/2",
  "web/download-address/download",
  "basic-setting/term-agreement-path",
];

function toastError(err) {
  // console.log(err)
  // message.error(err)
  MyToast({
    type: "error",
    message: err,
  });
}

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    console.log(config.url);
    if (!interceptUrlRequest.includes(config.url)) {
      config.headers.Authorization = `Bearer ${JsCookie.get(TokenKey)}`;
    }
    config.headers["content-type"] = "application/json;charset=UTF-8";
    // if (config.url === "/info/login") {
    //   config.headers.jsessionid2 = config.data.jsessionid2;
    //   config.headers.imageCode = config.data.imageCode;
    // }
    // loading = Loading.service({
    //   lock: true,
    //   text: '加载中',
    //   background: 'rgba(0, 0, 0, 0.5)'
    // })
    // console.log(config)
    return config;
  },
  (error) => {
    // loading.close()
    toastError("系统异常，请联系管理员");
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // loading.close()
    const data = response.data;
    if (data.code === 200) {
      if (response.headers["x-authentication-token"]) {
        if (store.state.user.autoLogin) {
          JsCookie.set(TokenKey, response.headers["x-authentication-token"], {
            expires: AutoLoginExpires,
          });
        } else {
          JsCookie.set(TokenKey, response.headers["x-authentication-token"]);
        }
      }
      return data.data;
    } else if (data.code === 403) {
      toastError(data.msg);
      removeUserData();
      setTimeout(() => {
        router.replace("/login").catch(() => {});
      }, 800);
      // const res = {
      //   jsessionid2: response.headers.jsessionid2,
      //   imgContent:
      //     "data:image/png;base64," +
      //     btoa(
      //       new Uint8Array(response.data).reduce(
      //         (data, byte) => data + String.fromCharCode(byte),
      //         ""
      //       )
      //     ),
      // };
      // return res;
      return Promise.reject(data);
    } else {
      if (data.msg) {
        toastError(data.msg);
      } else {
        toastError("系统异常，请联系管理员");
      }
      return Promise.reject(data);
    }
  },
  (error) => {
    // loading.close()
    if (error.response.data.msg) {
      toastError(error.response.data.msg);
    } else {
      toastError("系统异常，请联系管理员");
    }
    return Promise.reject(error.response);
  }
);

export default service;
