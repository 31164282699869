<template>
  <div class="advantages">
    <div class="advantages-title">核心优势</div>
    <p class="advantages-sub-title">依托国家数据跨境试点，跨境不再“灰色”</p>
    <p class="advantages-intro">
      商务部《关于印发全面深化服务贸易创新试点总体方案的通知》提出，积极开展数据跨境传输安全管理试点，由中央网信办指导并指定政策保障措施，中国（上海）自由贸易区临港新片区等试点地区负责推进。
    </p>
    <div class="more" @click="more">了解更多</div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push("/advantage");
    },
  },
};
</script>

<style lang="less" scoped>
.advantages {
  width: 100%;
  height: 750px;
  background: url("../../assets/images/home/advantages.jpg") center no-repeat;
  background-size: cover;
  padding-top: 199px;
  padding-left: 240px;
  position: relative;

  .advantages-title {
    color: #ffffff;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 97px;
    &::after {
      content: "";
      display: block;
      width: 180px;
      height: 20px;
      border-radius: 10px;
      background-color: #3661eb;
      margin-top: 8px;
    }
  }

  .advantages-sub-title {
    color: #ffffff;
    width: 640px;
    font-size: 32px;
    font-weight: bold;
    max-block-size: 42px;
  }
  .advantages-intro {
    width: 754px;
    text-indent: 2em;
    margin: 0;
    color: #dddddd;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .more {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0, #eb7a00, #f9c431);
    z-index: 2;
  }
}
</style>
