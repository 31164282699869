<template>
  <container-with-title title="合作伙伴" operate-text="">
    <div class="partner-container">
      <card-title title="深度合作"></card-title>
      <vue-seamless-scroll
        :data="deepPartner"
        class="wrap"
        :class-option="scrollOption"
      >
        <div class="list">
          <div class="item" v-for="item in deepPartner" :key="item.name">
            <div class="img-container" :class="item.classes">
              <img :src="item.icon" />
            </div>
            <span class="name">{{ item.name }}</span>
          </div>
        </div>
      </vue-seamless-scroll>
      <card-title title="合作平台"></card-title>
      <div class="list platforms">
        <div class="item" v-for="item in platforms" :key="item.name">
          <div class="img-container">
            <img :src="item.icon" />
          </div>
          <span class="name">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import CardTitle from "../../components/CardTitle";
import vueSeamlessScroll from "vue-seamless-scroll";
import ContainerWithTitle from "../../components/ContainerWithTitle";

export default {
  components: {
    ContainerWithTitle,
    CardTitle,
    vueSeamlessScroll,
  },
  data() {
    return {
      scrollOption: {
        direction: 2,
        openTouch: false,
        hoverStop: false,
      },
      deepPartner: [
        {
          name: "中国新闻出版传媒集团有限公司",
          icon: require("@/assets/images/home/中新集团.png"),
          classes: ["cmg"],
        },
        {
          name: "中国（上海）自由贸易试验区临港新片区",
          icon: require("@/assets/images/home/临港新片区.jpg"),
        },
        {
          name: "临港科技城",
          icon: require("@/assets/images/home/临港科技城.jpeg"),
        },
        {
          name: "临港跨境数科",
          icon: require("@/assets/images/home/跨境数科.jpeg"),
        },
        {
          name: "DING HE",
          icon: require("@/assets/images/home/dinghe.png"),
        },
      ],
      platforms: [
        {
          name: "TikTok",
          icon: require("@/assets/images/home/TikTok.jpg"),
        },
        {
          name: "Amazon",
          icon: require("@/assets/images/home/Amazon.jpg"),
        },
      ],
    };
  },

  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.partner-container {
  .wrap {
    overflow: hidden;
  }
  .list {
    display: flex;
    height: 288px;
    width: 100%;
    margin-top: 34px;
    margin-bottom: 100px;
    pointer-events: none;

    &.platforms {
      margin-bottom: 0;
    }

    .item {
      width: 503px;
      margin-right: 60px;
      flex-shrink: 0;
      @include flex(column, space-between);

      .img-container {
        height: 205px;
        background: #ffffff;
        border-radius: 17px;

        &.cmg {
          padding: 50px 90px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .name {
        @include ellipsis(1);
        text-align: center;
        font-size: 51px;
        font-weight: 500;
        color: #39425c;
      }
    }
  }
}
</style>
