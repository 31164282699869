<template>
  <div class="big-data">
    <p class="title">跨境直播大数据</p>
    <p class="sub-title">CROSS SEA LIVE BROADCAST BIG DATA</p>
    <p class="intro">
      找表现最好的直播商品找头部直播间&行业新星找最热门的在播直播间直播间数据分析复盘
    </p>
    <img src="@/assets/images/home/live.png" />
    <div class="more" @click="more">了解更多</div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push("/live-big-data");
    },
  },
};
</script>

<style lang="less" scoped>
.big-data {
  width: 100%;
  height: 750px;
  background: url("../../assets/images/home/bigdata.jpg") center no-repeat;
  background-size: cover;
  padding-top: 75px;
  position: relative;

  p {
    text-align: center;
    &.title {
      color: #ffffff;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.sub-title {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 81px;
      letter-spacing: 11px;
      opacity: 0.5;
      font-weight: bold;
    }
    &.intro {
      color: #dddddd;
      font-size: 24px;
      margin-bottom: 35px;
    }
  }

  img {
    width: 960px;
    margin: 0 auto;
  }

  .more {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    bottom: 68px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0, #eb7a00, #f9c431);
    z-index: 2;
  }
}
</style>
