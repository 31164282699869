import { getMenu } from "@/utils/utils";
import { HomeMenuKey, UserMenuKey } from "@/config/layout";
import { routes } from "@/router";

export default {
  state: {
    homeMenu: [], // 顶部导航菜单
    userMenu: [], // 用户导航菜单
  },
  actions: {},
  mutations: {},
  getters: {
    homeMenu(state) {
      if (state.homeMenu.length === 0) {
        state.homeMenu = getMenu(routes, HomeMenuKey);
      }
      return state.homeMenu;
    },
    userMenu(state) {
      if (state.userMenu.length === 0) {
        state.userMenu = getMenu(routes, UserMenuKey);
      }
      return state.userMenu;
    },
  },
};
