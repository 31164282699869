import { TokenKey } from "@/config/system";
import { UserInfoKey } from "@/config/user";
import JsCookie from "js-cookie";
import store from "@/store";
import copy from "copy-to-clipboard";

/**
 * 从路由数据中获取菜单
 * @param {object[]} routes 路由数据
 * @param {string} tag 菜单标志
 * @oaram {object[]} menu 菜单
 * @return {object[]}
 * */

export const getMenu = (routes, tag, menu = []) => {
  routes.forEach((item) => {
    const { path, meta = {}, children } = item;
    if (meta[tag]) {
      menu.push({
        path: path,
        title: meta ? meta.title : "",
        icon: meta ? meta.icon : "",
        children: children ? children : "",
      });
    }
    if (children && Array.isArray(children) && children.length > 0) {
      getMenu(children, tag, menu);
    }
  });
  return menu;
};

/**
 * @description 获取是否登录
 * */
export function getIsLogin() {
  return !!JsCookie.get(TokenKey);
}

/**
 * @description 设置用户信息到localStorage中
 * */
export function setUserInfoInLocal(userInfo) {
  localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
}

/**
 * @description 从localStorage中获取用户信息，若无，返回undefined
 * */
export function getUserInfoFromLocal() {
  let userInfoObj;
  try {
    const userInfoStr = localStorage.getItem(UserInfoKey);
    userInfoObj = JSON.parse(userInfoStr);
  } catch (err) {
    console.error(err);
  }
  console.log(userInfoObj);
  return userInfoObj;
}

/**
 * @description 保持用户信息不过期，当token失效时，去除用户信息
 * */
export function userInfoSyncWithToken() {}

/**
 * @description 去除用户相关信息
 * */
export function removeUserData() {
  localStorage.removeItem(UserInfoKey);
  sessionStorage.clear();
  store.commit("user/clearUserStore");
  JsCookie.remove(TokenKey);
}

/**
 * @description 一键复制
 * */
export function autoCopy(str) {
  return copy(str, {
    debug: true,
    message: "复制失败，请手动复制",
    format: "text/plain",
  });
}

/**
 * @description 判断是否是移动端
 * */
export function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return !!flag && getUrlParam(window.location.href, "device") !== "pc";
}

/**
 * @description 判断是否是ios
 * */
export function isIOS() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
}

/**
 * 获取字符串url携参
 * @param str{string}
 * @param key{string}
 * @returns {*}
 */
export function getUrlParam(str, key) {
  let theRequest = {};
  try {
    const url = decodeURI(str.split("?")[1]); // 获取url中"?"符后的字串
    if (url) {
      const strs = url.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = encodeURIComponent(
          strs[i].split("=")[1]
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
  return theRequest[key];
}
