<template>
  <container-with-title
    title="直播出海方案-e路播客户端"
    description="领先一步，跨海直播优选高性价比解决方案，手机/电脑安装客户端后一键连通海外直播。"
    operate-route="/programme/client"
  >
    <div slot="img" class="img-container">
      <div class="advantage">
        <div class="left">
          <div class="item">安全合规</div>
          <div class="item">高性价比</div>
        </div>
        <img
          class="phone-img"
          src="@/assets/images/home/overseas-client-phone.png"
        />
        <div class="right">
          <div class="item">随时随地开播</div>
          <div class="item">使用简单</div>
        </div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
  methods: {
    // more() {
    //   this.$router.push("/programme/client");
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.img-container {
  width: 100%;
  height: 614px;
  background: url("../../../assets/images/home/overseas-client.jpg") center
    center / cover no-repeat;
  border-radius: 17px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .advantage {
    height: 100%;
    position: relative;
    z-index: 3;
    @include flex(row, center, center);

    .left,
    .right {
      .item:first-child {
        margin-bottom: 50px;
      }
    }

    .item {
      width: 510px;
      height: 137px;
      background: rgba(0, 0, 0, 0.5);
      border: 2px solid #74726e;
      border-radius: 7px;
      font-size: 61px;
      font-weight: bold;
      color: #ecce03;
      line-height: 137px;
      text-align: center;
    }

    .phone-img {
      width: 234px;
      margin: 0 35px;
    }
  }
}
</style>
