<template>
  <div class="layout-footer">
    <div class="info">
      <div>
        <div class="phone">
          <div>
            <i class="iconfont icon-24gf-telephone2"></i>
            <span>联系方式</span>
          </div>
          <a href="mailto:service@zxkw.com.cn" class="link"
            >service@zxkw.com.cn</a
          >
        </div>
        <div class="entrance">
          <div>
            <i class="iconfont icon-kuaijiecaidan"></i>
            <span>快捷入口</span>
          </div>
          <div class="link" @click="toDetails(2)">隐私协议</div>
          <div class="link" @click="toDetails(1)">服务条款</div>
        </div>
      </div>
      <div class="wx">
        <div>
          <i class="iconfont icon-icon_weixin"></i>
          <span>关注微信公众号</span>
        </div>
        <img class="gzh" src="../../assets/images/wechat.jpg" alt="" />
      </div>
    </div>
    <div class="copy">
      Copyright 2021 中新宽维传媒科技有限公司. 备案号： 沪ICP备2021018354号
      沪公网安备31010402009514号
    </div>
  </div>
</template>

<script>
import { agreementPath } from "../../request/system";

export default {
  methods: {
    toDetails(type) {
      agreementPath({ type }).then((res) => {
        const { path } = res;
        if (path) {
          window.location.href = path;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.layout-footer {
  flex-shrink: 0;
  height: 939px;
  background: #3661eb;
  padding: 0 77px;
  @include flex(column);

  .info {
    height: 688px;
    border-bottom: 2px solid #6187ff;
    padding: 0 64px;
    @include flex(row, space-between, center);

    .link {
      font-size: 67px;
      font-weight: 500;
      text-decoration: underline;
      color: #ffffff;
      margin-left: 113px;
    }

    .phone,
    .entrance,
    .wx {
      & > div {
        &:first-child {
          @include flex(row, null, center);
          font-size: 61px;
          color: #afc2ff;
          margin-bottom: 32px;
        }
      }
    }

    .entrance .link {
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .phone {
      margin-bottom: 85px;
    }

    .icon-24gf-telephone2,
    .icon-kuaijiecaidan {
      font-size: 77px;
      margin-right: 36px;
    }

    .icon-icon_weixin {
      font-size: 84px;
    }

    .wx {
      @include flex(column, null, flex-end);
      .icon-icon_weixin {
        margin-right: 38px;
      }

      .gzh {
        width: 432px;
        height: 437px;
        background: #ffffff;
        border-radius: 17px;
        object-fit: contain;
        pointer-events: auto;
      }
    }
  }

  .copy {
    flex: 1;
    font-size: 51px;
    line-height: 77px;
    font-weight: 400;
    color: #eaf2fc;
    @include flex(row, center, center);
    text-align: center;
  }
}
</style>
