export const AuthStatus = Object.freeze({
  UNAUTH: 0,
  AUTHING: 2,
  AUTHED: 1,
  REFUSE: 3,
});

export const PaymentStatusEnum = Object.freeze({
  toBePaid: 0,
  paid: 1,
  closed: 2,
  cancel: 3,
});

// 支付状态
export const PaymentStatus = Object.freeze({
  [PaymentStatusEnum.toBePaid]: {
    name: "未支付",
  },
  [PaymentStatusEnum.paid]: {
    name: "已支付",
  },
  [PaymentStatusEnum.closed]: {
    name: "已关闭",
  },
  [PaymentStatusEnum.cancel]: {
    name: "已取消",
  },
});

export const PaymentTypeEnum = Object.freeze({
  trail: 0,
  alipay: 1,
  wxpay: 2,
  bank: 3,
});

export const PaymentType = Object.freeze({
  [PaymentTypeEnum.trail]: {
    name: "试用",
  },
  [PaymentTypeEnum.alipay]: {
    name: "支付宝支付",
    short: "支付宝",
  },
  [PaymentTypeEnum.wxpay]: {
    name: "微信支付",
    short: "微信支付",
  },
  [PaymentTypeEnum.bank]: {
    name: "银行转账",
    short: "银行转账",
  },
});

// 自动登录失效时间（天）
export const AutoLoginExpires = 30;

export const UserInfoKey = "userInfo";
