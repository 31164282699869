<template>
  <div class="layout-header">
    <img class="logo" src="@/assets/images/logo.png" alt="" />
    <div class="menu-container" v-if="!hideMenu">
      <div class="menu">
        <div v-for="item in menu" :key="item.path">
          <router-link
            :to="item.path"
            class="path clickable"
            :class="{ overseas: item.path === '/programme' }"
          >
            <span>
              {{ item.title }}
              <i v-if="item.children" class="iconfont icon-icon_xia"></i>
            </span>
            <div class="overseas-list-container" v-if="item.children">
              <div class="overseas-list">
                <router-link
                  v-for="i in item.children"
                  :key="i.path"
                  :to="i.path"
                >
                  {{ i.meta.title }}
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="action" v-if="!isLogin">
        <a-button-group>
          <a-button class="register" type="primary" ghost @click="toRegister"
            >注册</a-button
          >
          <a-button class="login" type="primary" @click="toLogin"
            >登录</a-button
          >
        </a-button-group>
      </div>
      <template v-else>
        <div class="circle">
          {{ userInfo.name ? userInfo.name.substr(0, 1) : "" }}
        </div>
        <a-dropdown>
          <router-link
            class="username clickable"
            to=""
            @click="(e) => e.preventDefault()"
          >
            <span class="text">{{ userInfo.name }}</span
            ><a-icon type="down" />
          </router-link>
          <a-menu slot="overlay" :visible="true" placement="bottomRight">
            <template v-for="item in userMenu">
              <a-menu-item :key="item.path" :style="menuItemStyle">
                <router-link :to="item.path" class="user-menu">
                  <i :class="[item.icon, 'iconfont']"></i>
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
              <a-menu-divider
                :key="`divider-${item.path}`"
                style="background-color: #e0e9ff"
              />
            </template>

            <a-menu-item @click="logOut" :style="menuItemStyle">
              <i class="iconfont icon-icon_tuichu-xian"></i>
              <span>退出系统</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import { Button, Dropdown, Menu, Icon, Modal } from "ant-design-vue";
import { mapGetters } from "vuex";
import { convertPxToVw } from "@/utils/format";
import { getIsLogin } from "@/utils/utils";
import { removeUserData } from "@/utils/utils";

export default {
  components: {
    [Button.Group.name]: Button.Group,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Menu.Divider.name]: Menu.Divider,
    [Icon.name]: Icon,
    [Modal.name]: Modal,
  },
  data() {
    return {
      menuItemStyle: {
        height: convertPxToVw(56),
        lineHeight: convertPxToVw(56),
        padding: `0 ${convertPxToVw(26)}`,
      },
      isLogin: false,
    };
  },
  watch: {
    $route() {
      this.isLogin = getIsLogin();
    },
  },
  created() {
    this.isLogin = getIsLogin();
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.menu);
    });
  },
  computed: {
    ...mapGetters({
      menu: "homeMenu",
      userMenu: "userMenu",
      userInfo: "user/userInfo",
    }),
    // 控制顶部右侧的显示，显示注册登录或者我的xx等操作
    hideMenu() {
      return this.$route.meta.hideMenu;
    },
  },
  methods: {
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    toRegister() {
      this.$router.push({
        path: "/register",
      });
    },
    logOut() {
      const that = this;
      Modal.confirm({
        title: "确定退出当前登录的账号吗？",
        content: () => <div>退出登录后，您将无法查看相关页面</div>,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          removeUserData();
          that.$router.push("/login");
        },
        centered: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

::v-deep .ant-dropdown-menu-item > a,
::v-deep .ant-dropdown-menu-submenu-title > a {
  margin: 0;
  padding: 0;
  height: 100%;

  //padding: 0;
}

::v-deep .anticon {
  font-size: 20px !important;
  margin-left: 10px;
}

@mixin router-active-or-hover {
  position: relative;
  &:after {
    content: "";
    width: 75px;
    height: 4px;
    background: $primary-color;
    border-radius: 2px;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.layout-header {
  width: 100%;
  height: 100px;
  // background: rgba(255, 255, 255, 0.7);
  background-color: #ffffff;
  padding: 0 106px;
  @include flex(row, space-between, center);
  position: relative;
  position: fixed;
  z-index: 99;
  top: 0;

  .logo {
    height: 68px;
  }

  .menu-container {
    //margin-left: 112px;
    flex: 1;
    @include flex(row, space-between);
    font-size: $font-size-menu-title;

    .menu {
      padding-left: 100px;
      // padding-right: 92px;
      // flex: 1;
      @include flex(row, space-between, center);

      & > div {
        margin-right: 80px;
        &:last-child {
          margin-right: 0;
        }
      }
      .overseas {
        position: relative;
        display: block;
        &:after {
          pointer-events: none;
        }
        .overseas-list-container {
          position: absolute;
          width: 146px;
          left: 50%;
          padding-top: 38px;
          margin-left: -73px;
          .overseas-list {
            border-radius: 0px 0px 10px 10px;
            overflow: hidden;
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
            display: none;
            a {
              display: block;
              width: 100%;
              height: 60px;
              line-height: 60px;
              text-align: center;
              background-color: #ffffff;
              color: #647897;
              font-size: 18px;
              border-bottom: 1px solid #e0e9ff;
              &:last-child {
                border-bottom: 0;
              }
              &:hover {
                color: $primary-color;
              }
              &.router-link-exact-active {
                color: $primary-color;
              }
            }
          }
        }
        &:hover {
          .overseas-list {
            display: block;
          }
        }
      }
    }

    .path {
      &:hover {
        color: $primary-color;
        @include router-active-or-hover();
      }

      // &.router-link-exact-active {
      &.router-link-active {
        color: $primary-color;
        @include router-active-or-hover();
      }
    }

    .circle {
      width: 50px;
      height: 50px;
      background: #3661eb;
      border-radius: 50%;
      margin: auto 8px auto auto;
      font-size: $font-size-menu-title;
      color: $white;
      @include flex(row, center, center);
    }

    .username {
      height: 100px;
      //display: inline-block;
      line-height: 100px;
      display: inline-flex;
      align-items: center;

      .text {
        max-width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        color: $primary-color;
      }

      &.router-link-active {
        color: $primary-color;
      }
    }

    .user-menu {
      display: inline-flex;
      align-items: center;
      .iconfont {
        font-size: 20px;
        margin-right: 8px;
      }
      &:hover {
        color: $primary-color;
      }

      &.router-link-active {
        color: $primary-color;
      }
    }
  }

  .action {
    button {
      width: 120px;
      height: 50px;
    }

    .register {
      border-radius: 10px 0px 0px 10px;
    }

    .login {
      border-radius: 0 10px 10px 0;
    }
  }
}
</style>
