<template>
  <div class="overseas-client">
    <div class="overseas-client-mask"></div>
    <p class="title">直播出海方案-客户端方案</p>
    <p class="sub-title">LIVE BROADCAST SEA GOING PLAN-CLIENT SCHEME</p>
    <div class="client-intro">
      <div class="main-intro">
        <img src="@/assets/images/home/overseas-client-phone.png" />
        <p>
          领先一步，跨海直播优选高性价比解决方案，手机/电脑安装客户端后一键连通海外直播。
        </p>
      </div>
      <div class="right-list">
        <div class="item">安全合规</div>
        <div class="item">高性价比</div>
        <div class="item">随时随地开播</div>
        <div class="item">使用简单</div>
      </div>
    </div>
    <div class="more" @click="more">了解更多</div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push("/programme/client");
    },
  },
};
</script>

<style lang="less" scoped>
.overseas-client {
  width: 100%;
  height: 750px;
  background: url("../../assets/images/home/overseas-client.jpg") center
    no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 118px;

  .overseas-client-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  p {
    text-align: center;
    position: relative;
    z-index: 2;
    &.title {
      color: #ffffff;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.sub-title {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 43px;
      letter-spacing: 9px;
      opacity: 0.5;
      font-weight: bold;
    }
  }

  .client-intro {
    width: 1440px;
    height: 380px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    position: relative;
    .main-intro {
      width: 1121px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border: 1px solid #74726e;
      border-radius: 4px;
      img {
        width: 172px;
        position: absolute;
        top: 25px;
        left: 65px;
      }
      p {
        width: 639px;
        color: #dddddd;
        font-size: 24px;
        text-align: left;
        text-indent: 2em;
        line-height: 36px;
        position: absolute;
        top: 152px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .right-list {
      width: 299px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 80px;
        line-height: 80px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #74726e;
        border-radius: 4px;
        text-align: center;
        color: #ecce03;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .more {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0, #eb7a00, #f9c431);
    z-index: 2;
  }
}
</style>
