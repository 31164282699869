import Vue from "vue";
import Toast from "./index.vue";

const ToastConstrutor = Vue.extend(Toast);
let instance;

const toast = function (options = {}) {
  if (typeof options === "string") {
    options = {
      message: options,
    };
  }
  instance = new ToastConstrutor({
    data: options,
  });
  document.body.appendChild(instance.$mount().$el);
};

export default toast;
