<template>
  <div class="card-title-container">
    <slot name="left">
      <div class="title-container">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.card-title-container {
  @include flex(row, space-between, center);
}

.title-container {
  @include flex(row, null, center);

  .title {
    font-size: $font-size-card-title;
    font-weight: bold;
    color: $primary-text-color;
    @include flex(row, null, center);

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 40px;
      background: $primary-color;
      border-radius: 5px;
      margin-right: 9px;
    }
  }
  .description {
    font-size: $font-size-description;
    color: #535d7a;
    margin-left: 24px;
  }
}
</style>
