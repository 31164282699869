import { getMenu } from "@/utils/utils";
import { HomeMenuKey } from "@/config/layout";
import { routes } from "@/router";

export default {
  state: {
    homeMenu: [], // 顶部导航菜单
    isMenuTransparent: false,
  },
  actions: {},
  mutations: {
    setMenuTransparent(state, isMenuTransparent) {
      state.isMenuTransparent = isMenuTransparent;
    },
  },
  getters: {
    homeMenu(state) {
      if (state.homeMenu.length === 0) {
        state.homeMenu = getMenu(routes, HomeMenuKey);
      }
      return state.homeMenu;
    },
    isMenuTransparent(state) {
      return state.isMenuTransparent;
    },
  },
};
