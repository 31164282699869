<template>
  <div id="app">
    <a-config-provider :autoInsertSpaceInButton="false" :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import { ConfigProvider } from "ant-design-vue";

export default {
  components: {
    [ConfigProvider.name]: ConfigProvider,
  },
  data() {
    return {
      locale: require("ant-design-vue/es/locale-provider/zh_CN").default,
    };
  },
};
</script>
