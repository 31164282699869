import dayjs from "dayjs";
import { isNumber } from "lodash";

/**
 * @description 转化px为vw，附以单位
 * @param {number} num
 */
export function convertPxToVw(num) {
  if (!num) return num;
  return `${((num * 100) / process.env.VUE_APP_VIEWPORT_WIDTH).toFixed(
    process.env.VUE_APP_UNIT_PRECISION
  )}vw`;
}

/**
 * @description 获取日期
 * @param {any} timeStamp 时间戳
 * @param {string} form 日期格式
 * @param {string} symbol 时间数据不对的返回
 */
export function formatDate(timeStamp, form = "YYYY.MM.DD", symbol = "--") {
  if (
    timeStamp === "" ||
    timeStamp === null ||
    timeStamp === undefined ||
    timeStamp === -1
  )
    return symbol;
  return dayjs(timeStamp).format(form);
}

/**
 * @description 把分转换为元为单位
 * @param {number} num
 */
export function formatCentToYuan(num, digit = 2) {
  if (!isNumber(num)) return;
  return `${(num / 100).toFixed(digit)}`;
}

export const filters = {
  formatDate,
  formatCentToYuan,
};
