<template>
  <div class="main-layout">
    <layout-header />
    <router-view />
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from "./LayoutHeader";
import LayoutFooter from "./LayoutFooter";

export default {
  components: { LayoutHeader, LayoutFooter },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

::v-deep img {
  pointer-events: none;
}

::v-deep body {
  background: rgba(0, 0, 0, 0);
}

.main-layout {
  min-height: 100%;
  padding-top: 225px;
  @include flex(column);
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
</style>
