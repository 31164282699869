<template>
  <div class="layout-header" :class="{ transparent: isMenuTransparent }">
    <div class="collapse">
      <img
        src="@/assets/images/logo.png"
        alt=""
        class="logo"
        v-if="$route.path === '/home'"
      />
      <div class="back" v-else @click="back">
        <i class="iconfont icon-icon_zuo"></i>
        <span>返回</span>
      </div>

      <div class="title">
        {{ $route.meta.shortTitle || $route.meta.title }}
      </div>
      <i @click="collapse = false" class="iconfont icon-daohang"></i>
    </div>

    <div class="open" v-if="!collapse">
      <div class="title">
        <div>快捷导航</div>
        <i class="iconfont icon-icon_guanbi-xian" @click="collapse = true"></i>
      </div>
      <div class="menu-list">
        <router-link
          class="menu-item"
          v-for="item in menus"
          :key="item.path"
          :to="item.path"
        >
          <i class="iconfont" :class="item.icon"></i>
          <div class="name">{{ item.title }}</div>
          <i class="iconfont icon-icon_you"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getMenu } from "../../utils/utils";
import { routes } from "../router";
import { homeMenuKey } from "../constant/system";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      collapse: true,
      menus: getMenu(routes, homeMenuKey),
    };
  },
  watch: {
    "$route.path"() {
      this.collapse = true;
    },
  },
  computed: {
    ...mapGetters(["isMenuTransparent"]),
  },
  methods: {
    back() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/home");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.layout-header {
  width: 100%;
  height: 225px;
  background: #ffffff;
  position: relative;
  padding: 0 77px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: background-color 0.3s;

  &.transparent {
    background: rgba(0, 0, 0, 0);
  }

  .collapse {
    width: 100%;
    height: 100%;
    @include flex(row, space-between, center);
    position: relative;
    text-align: center;

    .logo {
      width: 372px;
      height: 137px;
    }

    .back {
      width: 372px;
      height: 100%;
      @include flex(row, flex-start, center);
      font-size: 92px;
      color: #39425c;

      .iconfont {
        font-size: 92px;
        margin-right: 10px;
      }
    }

    .title {
      font-size: 92px;
      color: #39425c;
    }

    .icon-daohang {
      @include flex(row, flex-end, center);
      width: 372px;
      font-size: 102px;
      height: 100%;
    }
  }

  .open {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;

    .title {
      height: 225px;
      padding: 0 77px;
      font-size: 92px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #39425c;
      @include flex(row, space-between, center);

      border-bottom: 5px solid #dee7ff;

      .iconfont {
        color: #39425c;
        font-size: 89px;
        width: 140px;
        @include flex(row, flex-end, center);
        height: 100%;
      }
    }

    .menu-list {
      padding: 0 77px;
      //font-size: 82px;
      //color: #39425c;

      .menu-item {
        border-bottom: 5px dashed #dee7ff;
        padding-left: 53px;
        height: 254px;
        @include flex(row, space-between, center);

        &.router-link-exact-active {
          .iconfont:first-of-type {
            color: #4370f7;
          }
          .name {
            font-weight: bold;
            color: #4370f7;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .iconfont {
          font-size: 90px;

          &:first-of-type {
            margin-right: 34px;
            color: #6a7697;
          }
        }

        .name {
          flex: 1;
          font-size: 82px;
          color: #39425c;
        }

        .icon-icon_you {
          color: #acafb8;
        }
      }
    }
  }
}
</style>
