<template>
  <div class="container-with-title" :class="{ 'has-title': title }">
    <h3 class="title" v-if="title">
      <span class="left"></span>
      <span>{{ title }}</span>
      <span class="right"></span>
    </h3>
    <!--图片展示-->
    <template>
      <slot name="img"></slot>
      <img v-if="imgSrc" class="img" :src="imgSrc" alt="" />
    </template>
    <!--内容标题-->
    <div class="content-title" v-if="contentTitle">{{ contentTitle }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <slot></slot>
    <div class="operate" @click="clickOperate" v-if="operateText">
      <span>{{ operateText }}</span>
      <i class="iconfont icon-icon_you"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    imgSrc: String,
    contentTitle: String,
    description: String,
    operateText: {
      type: String,
      default: "了解更多",
    },
    operateRoute: String,
  },
  methods: {
    clickOperate() {
      this.operateRoute && this.$router.push(this.operateRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

.container-with-title {
  width: 1766px;
  //height: 1106px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 51px;
  padding: 68px;
  margin: 0 auto;

  &.has-title {
    padding-top: 102px;
  }

  .title {
    font-size: 82px;
    color: $primary-text-color;
    margin-bottom: 53px;
    position: relative;
    @include flex(row, center, center);

    .left,
    .right {
      display: inline-block;
      width: 171px;
      height: 10px;
      background: #67718c;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 10px;
        background: #67718c;
      }
    }

    .left {
      margin-right: 73px;

      &:before {
        right: -24px;
      }
    }

    .right {
      margin-left: 73px;
      &:before {
        left: -24px;
      }
    }
  }

  // 当有图片的时候
  .img {
    width: 100%;
    height: 614px;
    border-radius: 17px;
    margin-bottom: 50px;
    object-fit: cover;

    //&>img {
    //  wid
    //}
  }

  .content-title {
    font-size: 82px;
    font-weight: bold;
    color: $primary-text-color;
    //margin-bottom: 43px;

    & + div {
      margin-top: 43px;
    }
  }

  .description {
    font-size: 61px;
    color: $primary-text-color;
    line-height: 92px;
    text-indent: 122px;
    margin-bottom: 0;
    letter-spacing: 2px;
    text-align: justify;
  }

  .operate {
    @include flex(row, flex-end, center);
    font-size: 61px;
    font-weight: bold;
    color: #4370f7;
    margin-top: 45px;
    letter-spacing: 2px;

    .iconfont {
      //margin-left: 10px;
      font-size: 66px;
    }
  }
}
</style>
