<template>
  <div class="about-us">
    <div class="content">
      <p class="title">关于我们</p>
      <p class="sub-title">ABOUT US</p>
      <p class="content-text">
        中新宽维传媒科技有限公司是中国新闻出版传媒集团旗下混合所有制企业，2021年注册于中国（上海）自由贸易区临港新片区，公司通过运用大数据、人工智能、区块链等技术，建设国家级文化大数据平台，服务国家海外传播和文化走出去战略，开展对外宣传和国际传播，专注于数字经济和行业智慧+大数据服务，为具有远大抱负的出海企业、MCN机构、跨境直播间提供一站式出海网络服务及大数据服务。
      </p>
    </div>
    <div class="more" @click="more">了解更多</div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="less" scoped>
.about-us {
  width: 100%;
  height: 910px;
  background: url("../../assets/images/home/about-us.png") center no-repeat;
  background-size: cover;
  padding-top: 228px;
  position: relative;

  .content {
    width: 900px;
    padding: 69px 44px 36px 44px;
    background-color: rgba(255, 255, 255, 0.8);
    margin-left: 240px;
    border-radius: 10px;
    .title {
      color: #39425c;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }
    .sub-title {
      font-size: 18px;
      color: #39425c;
      margin-bottom: 59px;
      letter-spacing: 11px;
      text-align: center;
      opacity: 0.5;
      font-weight: bold;
    }
    .content-text {
      color: #39425c;
      font-size: 24px;
      text-indent: 2em;
      line-height: 36px;
      text-align: justify;
    }
  }
  .more {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    bottom: 103px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0, #eb7a00, #f9c431);
    z-index: 2;
  }
}
</style>
