import { AuthStatus } from "@/config/user";
import { getUserInfoFromLocal } from "@/utils/utils";
// import { checkRealName } from "@/request/user";
// import { TokenKey } from "@/config/system";

let defaultUserState = {
  userInfo: {},
  authStatus: AuthStatus.UNAUTH, // 实名状态， 0:未实名 1:实名中 2:已实名
  autoLogin: false,
};

export default {
  namespaced: true,
  state: { ...defaultUserState },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo || { ...defaultUserState.userInfo };
    },
    clearUserStore(state) {
      Object.assign(state, defaultUserState);
      console.log(state);
    },
    setAutoLogin(state, autoLogin) {
      state.autoLogin = autoLogin;
    },
    // async checkRealAuth(state) {
    //   state.authStatus !== AuthStatus.AUTHED &&
    //     checkRealName().then((res = {}) => {
    //       const { realNameAuthState } = res;
    //       state.authStatus = realNameAuthState;
    //     });
    // },
  },
  getters: {
    userInfo: (state) => {
      if (!state.userInfo.id) {
        state.userInfo = getUserInfoFromLocal() || {
          ...defaultUserState.userInfo,
        };
      }
      return state.userInfo;
    },
    // async getAuthStatus(state) {
    //   // if (state.authStatus !== AuthStatus.AUTHED) {
    //   await checkRealName().then((res = {}) => {
    //     const { realNameAuthState } = res;
    //     state.authStatus = realNameAuthState;
    //   });
    //   // }
    //   return state.authStatus;
    // },
  },
};
