<template>
  <div class="home">
    <home-nav />

    <banner />

    <support-platform />

    <advantages />

    <overseas-client />

    <overseas-special-line />

    <big-data />

    <about-us />

    <partner />
  </div>
</template>

<script>
import Banner from "./Banner";
import SupportPlatform from "./SupportPlatform";
import Advantages from "./Advantages";
import OverseasClient from "./OverseasClient";
import OverseasSpecialLine from "./OverseasSpecialLine";
import BigData from "./BigData";
import AboutUs from "./AboutUs";
import Partner from "./Partner";
import HomeNav from "./HomeNav";
export default {
  components: {
    Banner,
    SupportPlatform,
    Advantages,
    OverseasClient,
    OverseasSpecialLine,
    BigData,
    AboutUs,
    Partner,
    HomeNav,
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: -24px;
  margin-bottom: -24px;
  ::v-deep .more {
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transform: translateX(-50%) scale(1.05, 1.05);
    }
  }
}
</style>
