import VueRouter from "vue-router";
import Vue from "vue";
import Layout from "@/layout";
import Index from "@/views/index";
import { HomeMenuKey, UserMenuKey } from "@/config/layout";
import { AuthKey, TokenKey } from "@/config/system";

Vue.use(VueRouter);

/**
 * routes数据
 * {
 *   path,
 *   component,
 *   meta: {
 *     hideMenu, 是否隐藏顶部菜单
 *     hideHeader, 是否隐藏顶部导航栏
 *     hideFooter, 是否隐藏底部
 *   }
 * }
 * */
export const routes = [
  {
    path: "",
    component: Layout,
    children: [
      // 登录
      {
        path: "/login",
        component: () => import("@/views/login"),
        meta: {
          hideMenu: true,
          hideFooter: true,
          style: {
            background: `url(${require("@/assets/images/login-bg.png")}) center center / 100% 100% no-repeat`,
            // backgroundSize: "100% 100%",
          },
        },
      },
      {
        path: "/register",
        component: () => import("@/views/register"),
        meta: {
          hideMenu: true,
          hideFooter: true,
          style: {
            background: `url(${require("@/assets/images/register-bg.png")}) center center / 100% 100% no-repeat`,
            backgroundSize: "100% 100%",
          },
        },
      },
      // 忘记密码
      {
        path: "/forget-password",
        component: () => import("@/views/forgetPassword"),
        meta: {
          hideMenu: true,
          hideFooter: true,
          style: {
            background: `url(${require("@/assets/images/normal-bg.png")}) center center / 100% 100% no-repeat`,
            backgroundSize: "100% 100%",
          },
        },
      },
      // 首页
      {
        path: "/",
        redirect: "/home",
      },
      {
        path: "/home",
        component: Index,
        meta: {
          [HomeMenuKey]: true,
          title: "首页",
        },
      },
      // 核心优势
      {
        path: "/advantage",
        component: () => import("@/views/advantage"),
        meta: {
          [HomeMenuKey]: true,
          title: "核心优势",
        },
      },
      // 出海方案
      {
        path: "/programme",
        component: () => import("@/views/programme"),
        meta: {
          [HomeMenuKey]: true,
          title: "直播出海方案",
        },
        redirect: "/programme/client",
        children: [
          {
            path: "/programme/client",
            component: () => import("@/views/programme/client"),
            meta: {
              title: "客户端方案",
              [UserMenuKey]: true,
            },
          },
          {
            path: "/programme/special-line",
            component: () => import("@/views/programme/specialLine"),
            meta: {
              title: "专线方案",
              [UserMenuKey]: true,
            },
          },
        ],
      },
      // 直播大数据
      {
        path: "/live-big-data",
        component: () => import("@/views/liveBigData"),
        meta: {
          [HomeMenuKey]: true,
          title: "跨境直播大数据",
        },
      },
      // 关于我们
      {
        path: "/about",
        component: () => import("@/views/about"),
        meta: {
          [HomeMenuKey]: true,
          title: "关于我们",
        },
      },
      // 下载
      {
        path: "/download",
        component: () => import("@/views/download"),
        meta: {
          [HomeMenuKey]: true,
          title: "下载",
        },
      },
      {
        path: "/user",
        component: () => import("@/views/user"),
        meta: {
          auth: TokenKey,
        },
        children: [
          // 我的账户
          {
            path: "/user/info",
            component: () => import("@/views/user/info"),
            meta: {
              title: "我的账户",
              icon: "icon-icon_gerenzhongxin-mian",
              [UserMenuKey]: true,
              auth: TokenKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          // 我的订单
          {
            path: "/user/order",
            component: () => import("@/views/user/order"),
            meta: {
              title: "我的订单",
              icon: "icon-dingdandingdanmingxishouzhimingxi",
              [UserMenuKey]: true,
              auth: TokenKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          // 个人认证
          {
            path: "personal-auth",
            component: () => import("@/views/user/personal-auth"),
            meta: {
              auth: TokenKey,

              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          // 企业认证
          {
            path: "enterprise-auth",
            component: () => import("@/views/user/enterprise-auth"),
            meta: {
              auth: TokenKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          // 购买套餐
          {
            path: "/user/purchase",
            component: () => import("@/views/user/purchase"),
            meta: {
              auth: AuthKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          // 修改密码
          {
            path: "/user/change-password",
            component: () => import("@/views/user/changePassword"),
            meta: {
              [UserMenuKey]: true,
              title: "修改密码",
              icon: "icon-icon_mima",
              auth: TokenKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
        ],
      },
      {
        path: "/pay",
        component: () => import("@/views/pay/index"),
        meta: {
          auth: AuthKey,
        },
        children: [
          // 微信支付，支付宝支付
          {
            path: "/pay/:type(wx-pay|ali-pay)",
            component: () => import("@/views/pay/onLinePay"),
            meta: {
              auth: AuthKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
          {
            path: "/pay/bank-transfer",
            component: () => import("@/views/pay/bankTransfer"),
            meta: {
              auth: AuthKey,
              style: {
                background: `url(${require("@/assets/images/normal-bg.png")}) no-repeat`,
                backgroundSize: " 100% 100%",
              },
            },
          },
        ],
      },
    ],
  },
];

export default new VueRouter({
  routes,
});
