import VueRouter from "vue-router";
import Layout from "../layout";
import Index from "../views/index/index";
import { homeMenuKey } from "../constant/system";

export const routes = [
  {
    path: "",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: Index,
        meta: {
          title: "首页",
          icon: "icon-shouye",
          [homeMenuKey]: true,
        },
      },
      // 核心优势
      {
        path: "/advantage",
        component: () => import("../views/advantage"),
        meta: {
          title: "核心优势",
          icon: "icon-hexinwangzhuanxian",
          [homeMenuKey]: true,
        },
      },
      // 出海方案
      {
        path: "/programme",
        component: () => import("../views/programme"),
        redirect: "/programme/client",
        children: [
          {
            path: "/programme/client",
            component: () => import("../views/programme/client"),
            meta: {
              title: "直播出海方案-e路播客户端",
              shortTitle: "e路播客户端",
              icon: "icon-qiyekehu",
              [homeMenuKey]: true,
            },
          },
          {
            path: "/programme/special-line",
            component: () => import("../views/programme/specialLine"),
            meta: {
              title: "直播出海方案-e路播专线",
              shortTitle: "e路播专线",
              icon: "icon-zuanshi",
              [homeMenuKey]: true,
            },
          },
        ],
      },
      // 直播大数据
      {
        path: "/live-big-data",
        component: () => import("../views/liveBigData"),
        meta: {
          title: "跨境直播大数据",
          icon: "icon-dashuju___",
          [homeMenuKey]: true,
        },
      },
      // 关于我们
      {
        path: "/about",
        component: () => import("../views/about"),
        meta: {
          title: "关于我们",
          icon: "icon-tuandui",
          [homeMenuKey]: true,
        },
      },
    ],
  },
];

export default new VueRouter({
  routes,
});
