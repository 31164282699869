<template>
  <container-with-title title="支持平台" operate-text="">
    <div class="platform-list">
      <div
        class="platform-item"
        v-for="item in SupportPlatform"
        :key="item.name"
      >
        <div class="img-container">
          <img :src="item.icon" />
        </div>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import { SupportPlatform } from "../../constant/system";
import ContainerWithTitle from "../../components/ContainerWithTitle";

export default {
  components: { ContainerWithTitle },
  data() {
    return {
      SupportPlatform,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.platform-list {
  display: grid;
  grid-template-columns: 434px auto 434px;
  row-gap: 67px;
  .platform-item {
    @include flex(column, null, center);
    .img-container {
      width: 273px;
      height: 273px;
      background: #ffffff;
      box-shadow: 0px 10px 9px 1px rgba(0, 68, 142, 0.24);
      border-radius: 17px;
      overflow: hidden;
      margin-bottom: 43px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 61px;
      font-weight: bold;
      color: #242b55;
    }
  }
}
</style>
