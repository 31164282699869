import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/css/reset.css";
import "@/styles/css/common.css";
import VueI18n from "vue-i18n";
import { filters } from "@/utils/format";
import "@/styles/scss/change-antdv.scss";
import MyToast from "@/components/MyToast";
import "@/assets/iconfont/iconfont.css";
import "./permission";
import { Modal } from "ant-design-vue";
import { isMobile } from "./utils/utils";
import mobileRouter from "./mobile/router";
import mobileStore from "./mobile/store";
import mobileToast from "./mobile/plugins/MyToast";

const isPhone = isMobile();

Vue.use(Modal);

// 移动端和pc用到了不同的提示组件
isPhone ? Vue.use(mobileToast) : (Vue.prototype.$myToast = MyToast);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 页面路由
const webRouter = isPhone ? mobileRouter : router;

webRouter.afterEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
});

Vue.use(VueI18n);
const i18n = new VueI18n({
  //实例化VueI18n对象
  locale: window.sessionStorage.getItem("lang") || "zh-CN", //语言标识
  //this.$i18n.local   通过切换locale的值来实现语言切换
  messages: {
    "zh-CN": require("@/lang/zh.js"), // 中文语言
    "en-US": require("@/lang/en.js"), // 英文语言
  },
});

function refreshWhenPlatformChange() {
  isMobile() !== isPhone && window.location.reload();
}

// 监听页面变化
window.addEventListener("resize", () => {
  refreshWhenPlatformChange();
});

// 监听路由哈希变化，解决点击前进后退的平台的变化
window.addEventListener("hashchange", () => {
  refreshWhenPlatformChange();
});

new Vue({
  i18n,
  router: webRouter,
  store: isPhone ? mobileStore : store,
  render: (h) => h(App),
}).$mount("#app");
