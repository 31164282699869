// 按需加载antd中的icon，解决打包icon体积太大的问题
export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";
export { default as UpOutline } from "@ant-design/icons/lib/outline/UpOutline";
export { default as EyeOutline } from "@ant-design/icons/lib/outline/EyeOutline";
export { default as EyeInvisibleOutline } from "@ant-design/icons/lib/outline/EyeInvisibleOutline";
export { default as QuestionCircleOutline } from "@ant-design/icons/lib/outline/QuestionCircleOutline";
export { default as CalendarOutline } from "@ant-design/icons/lib/outline/CalendarOutline";
export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";

// export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";
// export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";
// export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";
// export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";
// export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";
// export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";
// export { default as EyeOutline } from "@ant-design/icons/lib/outline/EyeOutline";
// export { default as EyeInvisibleOutline } from "@ant-design/icons/lib/outline/EyeInvisibleOutline";
// export { default as CaretDownOutline } from "@ant-design/icons/lib/outline/CaretDownOutline";
// export { default as CalendarOutline } from "@ant-design/icons/lib/outline/CalendarOutline";
export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";
// export { default as ExclamationCircleFill } from "@ant-design/icons/lib/fill/ExclamationCircleFill";
// export { default as CheckCircleFill } from "@ant-design/icons/lib/fill/CheckCircleFill";
// export { default as QuestionCircleOutline } from "@ant-design/icons/lib/outline/QuestionCircleOutline";
// export { default as StarOutline } from "@ant-design/icons/lib/outline/StarOutline";
// export { default as StarFill } from "@ant-design/icons/lib/fill/StarFill";
