import router from "@/router";
import { AuthType, TokenKey, AuthKey } from "@/config/system";
import JsCookie from "js-cookie";
import { isMobile } from "./utils/utils";

const checkAuth = (type) => {
  switch (type) {
    case AuthType[TokenKey]:
      return !!JsCookie.get(TokenKey);
    case AuthType[AuthKey]:
      // return (
      //   !!sessionStorage.getItem(AuthKey) && !!sessionStorage.getItem(TokenKey)
      // );
      return !!JsCookie.get(TokenKey);
    case undefined:
      return true;
    default:
      break;
  }
  return false;
};

!isMobile() &&
  router.beforeEach((to, from, next) => {
    const { meta = {} } = to;
    const { auth = "" } = meta;
    if (auth) {
      const isAllow = checkAuth(auth);
      if (isAllow) {
        next();
      } else {
        next("/");
      }
    } else {
      next();
    }
  });
