import Toast from "./index.vue";

export default {
  install(Vue) {
    const ToastConstrutor = Vue.extend(Toast);
    const instance = new ToastConstrutor();
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);
    let timer;

    Vue.prototype.$myToast = (options = {}) => {
      clearTimeout(timer);
      instance.message = options.message;
      instance.type = options.type;
      instance.duration = options.duration || 1000;

      timer = setTimeout(() => {
        instance.message = "";
        instance.type = "";
      }, instance.duration);
    };
  },
};
