<template>
  <div class="home-banner">
    <div class="text">
      <p>直播企业出海</p>
      <p>一站式服务平台</p>
      <p>e路播帮助您快速出海</p>
    </div>
    <!--<img class="home-wifi-left" src="@/assets/images/home/home-wifi.png" />-->
    <!--<img class="home-phone" src="@/assets/images/home/home-phone.png" />-->
    <!--<img class="home-wifi-right" src="@/assets/images/home/home-wifi.png" />-->
    <!--<img class="home-phones" src="@/assets/images/home/home-phones.png" />-->
    <img class="home-pc" src="@/assets/images/home/home-pc.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.home-banner {
  width: 1766px;
  margin: 0 auto;
  height: 703px;
  @include flex();

  .text {
    width: 50%;
    @include flex(column, center, center);

    p {
      margin: 0;
      &:nth-child(1) {
        font-size: 123px;
        font-weight: bold;
        color: #242b55;
      }

      &:nth-child(2) {
        font-size: 67px;
        font-weight: bold;
        color: #242b55;
        margin-top: 34px;
        margin-bottom: 85px;
      }

      &:nth-child(3) {
        font-size: 51px;
        font-weight: 400;
        color: #242b55;
      }
    }
  }

  .home-pc {
    margin-top: 150px;
    width: 50%;
    object-fit: contain;
  }

  //background: url("../../../assets/images/home/home-bg.jpg") center no-repeat;
  //background-size: cover;
  //padding-top: 155px;
  //position: relative;
  //p {
  //  color: #242b55;
  //  text-align: center;
  //  &:first-child {
  //    font-size: 80px;
  //    margin-bottom: 55px;
  //    font-weight: bold;
  //  }
  //  &:nth-child(2) {
  //    font-size: 44px;
  //  }
  //}
  //.home-phone {
  //  width: 160px;
  //  position: absolute;
  //  bottom: 35px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //}
  //.home-wifi-left,
  //.home-wifi-right {
  //  width: 133px;
  //  position: absolute;
  //  bottom: 120px;
  //  left: 50%;
  //  z-index: 2;
  //}
  //.home-wifi-left {
  //  margin-left: -242px;
  //}
  //.home-wifi-right {
  //  margin-left: 110px;
  //  transform: rotate(180deg);
  //}
  //.home-phones {
  //  width: 506px;
  //  position: absolute;
  //  left: 179px;
  //  bottom: 86px;
  //}
  //.home-pc {
  //  width: 513px;
  //  //position: absolute;
  //  //right: 189px;
  //  //bottom: 59px;
  //}
}
</style>
