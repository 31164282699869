<template>
  <div class="layout-container">
    <layout-header v-if="$route.path !== '/home'" />
    <div class="view-container" :style="viewStyle">
      <router-view />
    </div>
    <layout-footer v-if="!$route.meta.hideFooter" />
  </div>
</template>

<script>
import LayoutHeader from "@/layout/header-comp";
import LayoutFooter from "@/layout/footer-comp";
export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  computed: {
    menu() {
      return [];
    },
    viewStyle() {
      return this.$route.meta.style;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.layout-container {
  height: 100%;
  @include flex(column);

  .view-container {
    flex: 1;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    //@include flex();
  }

  .layout-header + .view-container {
    margin-top: 100px;
  }
}
</style>
