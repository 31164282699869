<template>
  <div class="support-platform">
    <p>支持平台</p>
    <p>SUPPORT PLATFORM</p>
    <div class="platform-list">
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/TikTok.jpg" />
        </div>
        <span>TikTok</span>
      </div>
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/Amazon.jpg" />
        </div>
        <span>Amazon</span>
      </div>
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/Facebook.jpg" />
        </div>
        <span>Facebook</span>
      </div>
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/AliExpress.jpg" />
        </div>
        <span>AliExpress</span>
      </div>
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/Instagram.jpg" />
        </div>
        <span>Instagram</span>
      </div>
      <div class="platform-item">
        <div class="img-container">
          <img src="@/assets/images/home/Shopee.jpg" />
        </div>
        <span>Shopee</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.support-platform {
  width: 100%;
  height: 563px;
  padding-top: 87px;
  color: #242b55;
  p {
    text-align: center;
    &:first-child {
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 12px;
      opacity: 0.5;
      margin-bottom: 78px;
    }
  }
  .platform-list {
    display: flex;
    // margin: 0 auto;
    justify-content: center;
    .platform-item {
      margin: 0 48px;
      .img-container {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        box-shadow: 0px 6px 6px 0px rgba(0, 68, 142, 0.24);
        overflow: hidden;
        margin-bottom: 43px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      span {
        color: #242b55;
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
