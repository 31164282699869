<template>
  <div class="home-nav" :class="{ opaque: opaque }" ref="header">
    <img class="logo" src="@/assets/images/logo.png" />
    <div class="menu-container">
      <div class="menu">
        <div
          v-for="(item, index) in navMenu"
          :key="index"
          class="path"
          :class="{ active: index === currentMenuIndex }"
          @click="handleMenu(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="action" v-if="!isLogin">
        <a-button-group>
          <a-button class="register" type="primary" ghost @click="toRegister"
            >注册</a-button
          >
          <a-button class="login" type="primary" @click="toLogin"
            >登录</a-button
          >
        </a-button-group>
      </div>
      <template v-else>
        <div class="circle">
          {{ userInfo.name ? userInfo.name.substr(0, 1) : "" }}
        </div>
        <a-dropdown>
          <router-link
            class="username clickable"
            to=""
            @click="(e) => e.preventDefault()"
          >
            <span class="text">{{ userInfo.name }}</span
            ><a-icon type="down" />
          </router-link>
          <a-menu slot="overlay" :visible="true" placement="bottomRight">
            <template v-for="item in userMenu">
              <a-menu-item :key="item.path" :style="menuItemStyle">
                <router-link :to="item.path" class="user-menu">
                  <i :class="[item.icon, 'iconfont']"></i>
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
              <a-menu-divider
                :key="`divider-${item.path}`"
                style="background-color: #e0e9ff"
              />
            </template>

            <a-menu-item @click="logOut" :style="menuItemStyle">
              <i class="iconfont icon-icon_tuichu-xian"></i>
              <span>退出系统</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import { Button, Dropdown, Menu, Icon, Modal } from "ant-design-vue";
import { mapGetters } from "vuex";
import { convertPxToVw } from "@/utils/format";
import { getIsLogin } from "@/utils/utils";
import { removeUserData } from "@/utils/utils";

export default {
  components: {
    [Button.Group.name]: Button.Group,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Menu.Divider.name]: Menu.Divider,
    [Icon.name]: Icon,
    [Modal.name]: Modal,
  },
  data() {
    return {
      opaque: false,
      navMenu: [
        "首页",
        "核心优势",
        "直播出海方案",
        "跨境直播大数据",
        "关于我们",
        "合作伙伴",
      ],
      menuItemStyle: {
        height: convertPxToVw(56),
        lineHeight: convertPxToVw(56),
        padding: `0 ${convertPxToVw(26)}`,
      },
      isLogin: false,
      menuContOffsetTop: [],
      navContOffsetTop: [],
      currentMenuIndex: 0, // 当前选中的菜单index
      isEmitScroll: true, // 是否执行滚动事件
      headerH: 0, // 头部导航栏高度
    };
  },
  watch: {
    $route() {
      this.isLogin = getIsLogin();
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLogin = getIsLogin();
      this.headerH = this.$refs.header.offsetHeight;
      this.handleLoad();
      // window.addEventListener("load", this.handleLoad);
    }, 10);
  },
  beforeDestroy() {
    // window.removeEventListener("load", this.handleLoad);
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      menu: "homeMenu",
      userMenu: "userMenu",
      userInfo: "user/userInfo",
    }),
  },
  methods: {
    handleLoad() {
      this.getMenuContOffsetTop();
      window.addEventListener("scroll", this.handleScroll);
    },
    getMenuContOffsetTop() {
      this.menuContOffsetTop = this.$parent.$children.map((item) => {
        return item.$el.offsetTop;
      });
      setTimeout(() => {
        this.navContOffsetTop = [
          0,
          this.menuContOffsetTop[3],
          this.menuContOffsetTop[4],
          this.menuContOffsetTop[6],
          this.menuContOffsetTop[7],
          this.menuContOffsetTop[8],
        ];
      }, 100);
    },
    handleScroll() {
      if (!this.isEmitScroll) return; // 如果是点击菜单的滚动不执行滚动事件
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 3) {
        this.opaque = true;
      } else {
        this.opaque = false;
      }
      let minIndex = 0;
      let min = Infinity;
      this.menuContOffsetTop.forEach((item, index) => {
        const diff = Math.abs(item - scrollTop);
        if (min > diff) {
          min = diff;
          minIndex = index;
        }
      });
      if (minIndex < 3) {
        this.currentMenuIndex = 0;
      } else if (minIndex === 3 || minIndex === 4) {
        this.currentMenuIndex = minIndex - 2;
      } else if (minIndex > 4) {
        this.currentMenuIndex = minIndex - 3;
      }
    },
    handleMenu(index) {
      this.isEmitScroll = false;
      this.currentMenuIndex = index;
      // 获取当前元素
      let needHeight = this.navContOffsetTop[index] - this.headerH;
      // 记录当前滚动距离
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      let stepNum = (needHeight - scrollTop) / 8;
      let i = 0;
      let clearInter = setInterval(() => {
        i++;
        // 有方向的滚动
        window.scrollTo(0, scrollTop + stepNum * i);
        if (i >= 8) {
          this.isEmitScroll = true;
          clearInterval(clearInter);
        }
      }, 50);
    },
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    toRegister() {
      this.$router.push({
        path: "/register",
      });
    },
    logOut() {
      const that = this;
      Modal.confirm({
        title: "确定退出当前登录的账号吗？",
        content: () => <div>退出登录后，您将无法查看相关页面</div>,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          removeUserData();
          that.$router.push("/login");
        },
        centered: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

::v-deep .ant-dropdown-menu-item > a,
::v-deep .ant-dropdown-menu-submenu-title > a {
  margin: 0;
  padding: 0;
  height: 100%;
}

::v-deep .anticon {
  font-size: 20px !important;
  margin-left: 10px;
}

@mixin router-active-or-hover {
  position: relative;
  &:after {
    content: "";
    width: 75px;
    height: 4px;
    background: $primary-color;
    border-radius: 2px;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.home-nav {
  width: 100%;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 106px;
  display: flex;
  align-items: center;
  &.opaque {
    background-color: #ffffff;
  }

  .logo {
    height: 68px;
  }

  .menu-container {
    flex: 1;
    @include flex(row, space-between);
    font-size: $font-size-menu-title;

    .menu {
      padding-left: 100px;
      @include flex(row, space-between, center);
      & > div {
        margin-right: 80px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .path {
      &:hover {
        color: $primary-color;
        @include router-active-or-hover();
      }
      &.active {
        color: $primary-color;
        @include router-active-or-hover();
      }
    }

    .circle {
      width: 50px;
      height: 50px;
      background: #3661eb;
      border-radius: 50%;
      margin: auto 8px auto auto;
      font-size: $font-size-menu-title;
      color: $white;
      @include flex(row, center, center);
    }

    .username {
      height: 100px;
      //display: inline-block;
      line-height: 100px;
      display: inline-flex;
      align-items: center;

      .text {
        max-width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        color: $primary-color;
      }

      &.router-link-active {
        color: $primary-color;
      }
    }

    .user-menu {
      display: inline-flex;
      align-items: center;
      .iconfont {
        font-size: 20px;
        margin-right: 8px;
      }
      &:hover {
        color: $primary-color;
      }

      &.router-link-active {
        color: $primary-color;
      }
    }
  }

  .action {
    button {
      width: 120px;
      height: 50px;
    }

    .register {
      border-radius: 10px 0px 0px 10px;
    }

    .login {
      border-radius: 0 10px 10px 0;
    }
  }
}
</style>
