<template>
  <div class="layout-footer">
    <div class="copyright">
      <p>
        <span @click="toDetails(2)">隐私协议</span>
        <span @click="toDetails(1)">服务条款</span>
      </p>
      <p>
        Copyright 2021 中新宽维传媒科技有限公司. 备案号： 沪ICP备2021018354号
      </p>
      <p>沪公网安备31010402009514号</p>
    </div>
    <div class="phone-number">
      <i class="iconfont icon-24gf-telephone2"></i>
      <div>
        <span>联系方式</span>
        <a href="mailto:service@zxkw.com.cn" class="link"
          >service@zxkw.com.cn</a
        >
      </div>
    </div>
    <div class="wechat">
      <i class="iconfont icon-icon_weixin"></i>
      <div>
        <span>关注微信公众号</span>
        <div>
          <img src="@/assets/images/wechat.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { agreementPath } from "../request/system";

export default {
  methods: {
    toDetails(type) {
      agreementPath({ type }).then((res) => {
        const { path } = res;
        if (path) {
          window.open(path);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.layout-footer {
  flex-shrink: 0;
  width: 100%;
  height: 240px;
  background: $primary-color;
  padding: 60px 240px 45px 240px;
  display: flex;

  .copyright {
    display: flex;
    flex-direction: column;
    p {
      color: #eaf2fc;
      font-size: 14px;
      margin: 0;
      span {
        display: inline-block;
        color: #ffffff;
        font-size: 20px;
        margin-right: 32px;
        margin-bottom: 60px;
        cursor: pointer;
      }
      &:last-child {
        margin-top: auto;
      }
    }
  }

  .phone-number {
    margin-left: auto;
    margin-right: 90px;
    display: flex;
    .iconfont {
      font-size: 45px;
      color: #ffffff;
      margin-right: 14px;
      & + div {
        .link,
        span {
          display: block;
          color: #ffffff;
          &:first-child {
            font-size: 16px;
            margin-bottom: 9px;
          }
          &:last-child {
            font-size: 20px;
          }
        }
      }
    }
  }

  .wechat {
    display: flex;
    .iconfont {
      font-size: 49px;
      color: #ffffff;
      margin-right: 12px;
      & + div {
        span {
          display: inline-block;
          color: #ffffff;
          font-size: 16px;
          margin-bottom: 10px;
        }
        div {
          width: 109px;
          height: 109px;
          overflow: hidden;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
