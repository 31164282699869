<template>
  <div id="my-toast" :class="`toast-${type}`" v-if="message">
    <i
      class="iconfont"
      :class="type === 'success' ? 'icon-chenggong-17' : 'icon-cuowu-10'"
    ></i>
    <span class="msg">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      type: "",
      message: "",
      duration: 2000,
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#my-toast {
  min-width: 1195px;
  position: fixed;
  top: 652px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 57px;
  text-align: center;

  border-radius: 51px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.toast-success {
    color: #23c48d;
    background: rgba(35, 196, 141, 0.3);
    border: 5px solid #23c48d;
  }

  &.toast-error {
    color: #f56c6c;
    border: 1px solid #ffd9d9;
    background-color: #fef0f0;
  }

  .iconfont {
    font-size: 102px;
    margin-right: 38px;
  }
  .msg {
    font-size: 82px;
  }
}
</style>
