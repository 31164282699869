<template>
  <div class="home">
    <div class="menu-bg">
      <banner />
    </div>

    <support-platform class="support-platform" />

    <advantages />

    <overseas-client />

    <overseas-special-line />

    <big-data />

    <about-us />

    <partner />
  </div>
</template>

<script>
import Banner from "./Banner";
import SupportPlatform from "./SupportPlatform";
import Advantages from "./Advantages";
import OverseasClient from "./OverseasClient";
import OverseasSpecialLine from "./OverseasSpecialLine";
import BigData from "./BigData";
import AboutUs from "./AboutUs";
import Partner from "./Partner";
import { mapMutations } from "vuex";
// import HomeNav from "./HomeNav";
export default {
  components: {
    Banner,
    SupportPlatform,
    Advantages,
    OverseasClient,
    OverseasSpecialLine,
    BigData,
    AboutUs,
    Partner,
    // HomeNav,
  },
  mounted() {
    this.setMenuTransparent(true);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.setMenuTransparent(false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations(["setMenuTransparent"]),
    handleScroll() {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.setMenuTransparent(scrollTop === 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #d3e0ff;
  padding-bottom: 50px;

  .menu-bg {
    margin-top: -225px;
    padding-top: 225px;
    background: url("../../assets/images/home/menu-bg.png") center no-repeat;
    background-size: cover;
    height: 1650px;
    //margin-bottom: -722px;
  }

  .support-platform {
    margin-top: -722px;
  }

  .container-with-title {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
