<template>
  <container-with-title
    title="直播出海方案-e路播专线"
    operate-route="/programme/special-line"
  >
    <div slot="img" class="img-container">
      <div class="advantage">
        <div class="left">
          <div class="item">安全合规</div>
          <div class="item">稳定直播</div>
        </div>
        <img
          class="phone-img"
          src="@/assets/images/home/special-line-phone.png"
        />
        <div class="right">
          <div class="item">飞速体验</div>
          <div class="item">使用简单</div>
        </div>
      </div>
    </div>
    <div class="center-intro">
      <div>
        <span>专线出海</span>
        <span>低延迟告别卡顿</span>
      </div>
      <div>
        <span>150+</span>
        <span>覆盖国家/地区</span>
      </div>
      <div>
        <span>80+</span>
        <span>国际运营商长期深度合作</span>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";
export default {
  components: {
    ContainerWithTitle,
  },
  methods: {
    // more() {
    //   this.$router.push("/programme/special-line");
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

//@media screen and (max-width: 375px) {
//  .center-intro span:last-child {
//    transform: scale(0.7);
//  }
//}

.img-container {
  width: 100%;
  height: 614px;
  background: url("../../../assets/images/home/special-line.jpg") center center /
    cover no-repeat;
  border-radius: 17px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .advantage {
    height: 100%;
    position: relative;
    z-index: 3;
    @include flex(row, center, center);

    .left,
    .right {
      .item:first-child {
        margin-bottom: 50px;
      }
    }

    .item {
      width: 510px;
      height: 137px;
      background: rgba(0, 0, 0, 0.5);
      border: 2px solid #74726e;
      border-radius: 7px;
      font-size: 61px;
      font-weight: bold;
      color: #ecce03;
      line-height: 137px;
      text-align: center;
    }

    .phone-img {
      width: 392px;
      margin: 0 35px;
    }
  }
}

.center-intro {
  margin-bottom: 100px;
  @include flex(row, space-between);
  & > div {
    width: 509px;
    height: 200px;
    border-radius: 17px;
    @include flex(column, center, center);
    &:first-child {
      background: linear-gradient(180deg, #0bbafb 0%, #4285ec 100%);
      box-shadow: 0px 14px 15px 2px rgba(69, 134, 245, 0.3);
      & > span:last-child {
        color: #d8e2ff;
        font-weight: bold;
      }
    }

    &:nth-child(2) {
      background: linear-gradient(180deg, #f7cb6b 0%, #fba980 100%);
      box-shadow: 0px 14px 15px 2px rgba(250, 173, 125, 0.3);

      & > span:last-child {
        color: #fff3eb;
        font-weight: bold;
      }
    }

    &:last-child {
      background: linear-gradient(0deg, #4be6cf 0%, #d4f8ae 100%);
      box-shadow: 0px 14px 15px 2px rgba(82, 231, 205, 0.3);

      & > span:last-child {
        color: #defffa;
        font-weight: bold;
      }
    }
  }
  span {
    &:first-child {
      color: #ffffff;
      font-size: 61px;
      font-weight: bold;
    }
    &:last-child {
      font-size: 41px;
      //transform: scale(0.7);
      word-break: keep-all;
    }
  }
}
</style>
