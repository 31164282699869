<template>
  <container-with-title
    class="about-us"
    title="关于我们"
    :img-src="require('../../../assets/images/home/about-us.png')"
    description="中新宽维传媒科技有限公司是中国新闻出版传媒集团旗下混合所有制企业，2021年注册于中国（上海）自由贸易区临港新片区，公司通过运用大数据、人工智能、区块链等技术，建设国家级文化大数据平台，服务国家海外传播和文化走出去战略，开展对外宣传和国际传播，专注于数字经济和行业智慧+大数据服务，为具有远大抱负的出海企业、MCN机构、跨境直播间提供一站式出海网络服务及大数据服务。"
    operate-route="/about"
  >
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
  methods: {
    // more() {
    //   this.$router.push("/about");
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.about-us {
  ::v-deep .description {
    @include ellipsis(2);
  }
}
</style>
