<template>
  <div class="overseas-special-line">
    <p class="title">直播出海方案-专线方案</p>
    <p class="sub-title">LIVE BROADCAST SEA GOING PLAN-SPECIAL LINE</p>
    <div class="special-line-intro">
      <div class="left-intro">
        <img src="@/assets/images/home/special-line-phone.png" />
      </div>
      <div class="center-intro">
        <div>
          <span>专线出海</span>
          <span>低延迟告别卡顿</span>
        </div>
        <div>
          <div>
            <span>150+</span>
            <span>覆盖国家/地区</span>
          </div>
          <div>
            <span>80+</span>
            <span>国际运营商长期深度合作</span>
          </div>
        </div>
      </div>
      <div class="right-intro">
        <div class="item">安全合规</div>
        <div class="item">稳定直播</div>
        <div class="item">飞速体验</div>
        <div class="item">使用简单</div>
      </div>
    </div>
    <div class="more" @click="more">了解更多</div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push("/programme/special-line");
    },
  },
};
</script>

<style lang="less" scoped>
.overseas-special-line {
  width: 100%;
  height: 750px;
  background: url("../../assets/images/home/special-line.jpg") center no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 98px;

  p {
    text-align: center;
    &.title {
      color: #ffffff;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.sub-title {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 41px;
      letter-spacing: 11px;
      opacity: 0.5;
      font-weight: bold;
    }
  }

  .special-line-intro {
    width: 1440px;
    height: 380px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left-intro,
    .right-intro {
      width: 300px;
      height: 100%;
    }
    .center-intro {
      width: 800px;
      height: 330px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div {
        width: 100%;
        height: 160px;
        &:first-child {
          border: 1px solid #9096b0;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          padding: 46px 0 23px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        &:last-child {
          display: flex;
          justify-content: space-between;
          & > div {
            width: 395px;
            height: 160px;
            border: 1px solid #9096b0;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            padding: 50px 0 23px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      span {
        &:first-child {
          color: #ecce03;
          font-size: 40px;
          font-weight: bold;
        }
        &:last-child {
          color: #dddddd;
          font-size: 24px;
        }
      }
    }
    .left-intro {
      border: 1px solid #9096b0;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
      img {
        width: 240px;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .right-intro {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 80px;
        line-height: 80px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #74726e;
        border-radius: 4px;
        text-align: center;
        color: #ecce03;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .more {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    bottom: 68px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0, #eb7a00, #f9c431);
    z-index: 2;
  }
}
</style>
