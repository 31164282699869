// export const MenuConfig = Object.freeze({
//   index: "/",
//
//   download:  "/download",
// });

// export const userMenuConfig = {
//   info: "/user/info",
//   order: {
//     path: "/user/order",
//     title: "我的订单",
//   },
//   changePwd: {
//     path: "/change-password",
//     title: "修改密码",
//   },
// };

export const HomeMenuKey = "homeMenu";
export const UserMenuKey = "userMenu";

// toast类型
export const TipType = Object.freeze({
  normal: "normal",
  success: "success",
  error: "error",
});

// 企业证件类型
export const CertificatesTypes = Object.freeze({
  1: {
    name: "营业执照",
  },
});

// 个人证件类型
export const PersonalCertTypes = Object.freeze({
  0: {
    name: "身份证",
  },
});

// 套餐类型
export const ProductTypeEnum = Object.freeze({
  Trail: 1,
  Formal: 2,
});
