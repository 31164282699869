module.exports = {
  placeholder: {
    phone: "请输入手机号",
    code: "请输入短信验证码",
    register: {
      password: "请输入6-18位登录密码",
    },
    login: {
      password: "请输入密码",
    },
    changePassword: {
      original: "请输入原密码",
      confirm: "请再次输入新密码",
    },
  },
  layout: {
    register: {
      title: "注册",
      codeStart: "点击获取验证码",
      code: "获取验证码",
      reCode: "重新获取",
      haveAccount: "已有账号",
      login: "去登录",
    },

    user: {
      info: {
        title: "我的账户",
        accountTitle: "账户信息",
        phone: "注册手机号",
        authed: "已实名",
        noAuth: "未实名",
        authing: "实名认证审核中，预计1~3个工作日完成",
        deviceTitle: "设备数量",
        deviceUnit: "台",
        total: "总计",
        deviceDescription: "多设备可同时使用",
        renew: "优惠续费",
        noBuyTitle: "未购买套餐",
        buyNow: "立即购买套餐",
      },
      auth: {
        personal: {
          title: "个人身份证认证",
        },
        description: "1~3个工作日完成审核",
        code: "获取验证码",
        submit: "提交信息",
        form: {
          name: {
            title: "姓名",
            placeholder: "请输入姓名",
          },
          certificates: {
            title: "证件类型",
          },
          no: {
            title: "证件号码",
            placeholder: "请输入证件号码",
          },
          phone: {
            title: "手机验证码",
            placeholder: "请输入短信验证码",
            btn: "获取验证码",
          },
          statement:
            "请务必提供真实信息，中新宽维有权自行或委托第三方，" +
            "审查您提供的身份信息是否真实、有效，若提供虚假信息，" +
            "由此带来的全部结果由您承担。",
        },
      },
    },
    authModal: {
      title: "选择实名认证方式",
      confirm: "确定",
      cancel: "取消",
      personal: {
        title: "个人",
        description: [
          "1.适用个人用户，账号归属于个人；",
          "2.支持个人身份证认证；",
          "3.不支持开增值税专用发票；",
          "4.需年满18周岁以上。",
        ],
      },
      enterprise: {
        title: "企业/组织",
        description: [
          "1.适用企业、党政及国家机关、事业单位、民办非企业单位、社会团体、个体工商户等，账号归属于企业；",
          "2.支持企业证件认证；",
          "3.支持开增值税专用发票；",
        ],
      },
    },
  },
  message: {
    buyWhenNoAuth: "请先完成实名认证后购买",
  },
};
