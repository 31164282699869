// 连接方式
export const ConnectionTypeEnum = Object.freeze({
  Vpn: 1,
  Direct: 2,
});

// 支持平台
export const SupportPlatform = [
  {
    name: "TikTok",
    icon: require("@/assets/images/home/TikTok.jpg"),
  },
  {
    name: "Amazon",
    icon: require("@/assets/images/home/Amazon.jpg"),
  },
  {
    name: "Facebook",
    icon: require("@/assets/images/home/Facebook.jpg"),
  },
  {
    name: "AliExpress",
    icon: require("@/assets/images/home/AliExpress.jpg"),
  },
  {
    name: "Instagram",
    icon: require("@/assets/images/home/Instagram.jpg"),
  },
  {
    name: "Shopee",
    icon: require("@/assets/images/home/Shopee.jpg"),
  },
];

export const homeMenuKey = "mobile.aboard.homeMenuKey";

// 官网地址
export const websiteUrl =
  process.env.NODE_ENV === "development"
    ? "http://dev-alsp.zxkw-local.com/#/"
    : process.env.NODE_ENV === "testing"
    ? "http://test-alsp.zxkw-local.com/#/"
    : "https://alsp.zxkw.com.cn/#/";
